@import 'styles/variables.scss';

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: $primary-color;
}

.text-white {
  color: white !important;
}

.float-right {
  float: right;
}

.ml-auto {
  margin-left: auto;
}
.ml-4 {
  margin-left: ($spacer * 1) !important;
}

.mt-0 {
  margin-top: 0;
}
.mt-4 {
  margin-top: ($spacer * 1) !important;
}
.mt-8 {
  margin-top: ($spacer * 2) !important;
}

.mb-0 {
  margin-bottom: 0;
}
.mb-4 {
  margin-bottom: ($spacer * 1) !important;
}

.mr-auto {
  margin-right: auto;
}
.mr-0 {
  margin-top: 0 !important;
}
.mr-1 {
  margin-right: ($spacer * 0.25) !important;
}
.mr-2 {
  margin-right: ($spacer * 0.5) !important;
}
.mr-4 {
  margin-right: ($spacer * 1) !important;
}

.m-0 {
  margin: 0;
}
.my-4 {
  margin-top: $spacer !important;
  margin-bottom: $spacer !important;
}
.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}


.py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.bold {
  font-weight: bold;
}

.cursor-default {
  cursor: default !important;
}

.d-flex {
  display: flex;
}

.justify-content-end {
  justify-content: flex-end;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
