@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.row {
  padding: 0;
  p {
    text-align: justify;
  }
  @include breakpoint-up(md) {
    padding: 20px;
  }
  h3 {
    margin-top: 0;
  }
}
.showModalBtn {
  position: relative;
  width: 30px;
  height: 23px;
  padding-left: 10px;
  svg {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
}
